import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import CotizacionInfo from "./CotizacionInfo";
import GeneralesCotizacion from "./GeneralesCotizacion";
import {
  cotizacionListIndividual,
  cotizacionListResults,
  cotizacionListTriple,
  servicioDayElementI,
  serviciosDaysElementsI,
} from "../../../context/InterfacesContext";
import ElementServiceDay from "./ElementServiceDay";
import {
  getDaysDifference,
  createServicioPasajeroList,
  createServicioPasajeroListOpen,
} from "../RegisterNewProvider-Client/FunctionsTarifa";
import { useCatalogs } from "../../../context/CatalogContext";
import { coinInterface } from "../../../interfaces/GeneralTypes";
import { areDatesValid } from "../RegisterNewProvider-Client/FunctionsTarifa";
import { serviciosDaysElementsInicial } from "../../../context/initialContext";

interface serviciosDaysI {
  listSingle: cotizacionListIndividual[];
  setListSingle: React.Dispatch<
    React.SetStateAction<cotizacionListIndividual[]>
  >;
  listTriple: cotizacionListTriple[];
  setListTriple: React.Dispatch<React.SetStateAction<cotizacionListTriple[]>>;
}

export default function TransporteServices({
  listSingle,
  setListSingle,
  listTriple,
  setListTriple,
}: serviciosDaysI) {
  const { catalogsData } = useCatalogs();
  const {
    dataCotizacionContext,
    setDataCotizacionContext,
    currentCurrency,
    setCurrentCurrency,
    serviciosType,
  } = useContext(AppContext);
  const [servicioPasajeroList, setServicioPasajeroList] = useState<
    servicioDayElementI[]
  >(dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement);
  useEffect(() => {
    console.log("INICIALIZANDO COMPONENTES; ESTOS SON LOS VALORES::::");
    console.log(
      dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement
    );
  }, []);
  const [numberDays, setNumberDays] = useState<number>(0);
  const [sumaServicios, setSumaServicios] = useState<number>(0);
  let identifierDay = uuidv4();
  let identifierService = uuidv4();
  const servicioDayElementInicial: servicioDayElementI = {
    identifierDay: identifierDay,
    validityStartDay: "startDate",
    validityEndDay: "endDate",
    category: 0,
    lendertype: 0,
    ocupacion: 0,
    providerType: 0,
    serviceType: 0,
    tarifa: 0,
    isAvailable: true,
    travelRoute: "",
    service_identifier: 0,

    serviciosIndividuales: [
      {
        category: "",
        lendertype: 0,
        lenderDescripcion: "Nombre del prestador actual DOS",
        ocupacion: "",
        ocupacionDescripcion: "Nombre de la ocupación actual",
        providerType: 0,
        providerDescripcion: "Nombre del proveedor actual",
        serviceType: 0,
        serviceDescripcion: "Descripción del servicio actual",
        tarifa: 0,
        identifierService: identifierService,
        typeRoom: 0,
        dateRequired: "",
        currencyName: "Peso",
        currencyId: 1,
        number_of_services: 0,
        service_name_identifier: 0,
        service_name: "serviceDayy.service_name",
        isIncludedOpt: false,
      },
    ],
  };

  ///CAMBIO DE DIAS

  useEffect(() => {
    if (dataCotizacionContext.personalInfo.modeCotizacion == "Serie") {
      if (!isNaN(dataCotizacionContext.personalInfo.numberDaysOpenQuote)) {
        setNumberDays(dataCotizacionContext.personalInfo.numberDaysOpenQuote);
        const servicioPasajeroList = createServicioPasajeroListOpen(
          dataCotizacionContext.personalInfo.numberDaysOpenQuote
        );
        setServicioPasajeroList(servicioPasajeroList);
      } else {
        setNumberDays(0);
      }

      return;
    }

    const diferenciaDias = getDaysDifference(
      dataCotizacionContext.personalInfo.arrivingData,
      dataCotizacionContext.personalInfo.departureDate
    );
    console.log(
      "calculando diferencia de días_-----------------------____________"
    );
    console.log(diferenciaDias);
    if (diferenciaDias > 0 && diferenciaDias < 50) {
      if (
        !areDatesValid(
          dataCotizacionContext.personalInfo.arrivingData,
          dataCotizacionContext.personalInfo.departureDate
        )
      ) {
        alert(
          "La fecha de llegada no puede ser mayor a la fecha de salida, por favor corrige tus fechas"
        );
        setNumberDays(0);
        setDataCotizacionContext({
          ...dataCotizacionContext,
          personalInfo: {
            ...dataCotizacionContext.personalInfo,
            arrivingData: "",
            departureDate: "",
          },
        });
      } else {
        setNumberDays(diferenciaDias);

        console.log("Calculando diferencia de dias en transporte");
        console.log(diferenciaDias);

        const servicioPasajeroList = createServicioPasajeroList(
          dataCotizacionContext.personalInfo.arrivingData,
          dataCotizacionContext.personalInfo.departureDate
        );

        console.log("Así tiene que quedar la lista de TRANSPORTE:");
        console.log(servicioPasajeroList);
        setServicioPasajeroList(servicioPasajeroList);
      }
    }
  }, [
    dataCotizacionContext.personalInfo.arrivingData,
    dataCotizacionContext.personalInfo.departureDate,
    dataCotizacionContext.personalInfo.modeCotizacion,
    dataCotizacionContext.personalInfo.numberDaysOpenQuote,
  ]);

  useEffect(() => {
    console.log("CONTEXTO NUEVO COTIZACCION!!!!!");
    console.log(dataCotizacionContext);
    setDataCotizacionContext({
      ...dataCotizacionContext,
      serviciosDayElementsTransporte: {
        servicioDayElement: servicioPasajeroList,
      },
    });
    console.log("SERVICIO PASAJERO LIST: ");
    console.log(servicioPasajeroList);
  }, [servicioPasajeroList]);
  useEffect(() => {
    console.log("DATOS PARA USAR EN COTIZACION:");
    console.log(dataCotizacionContext);

    setServicioPasajeroList(
      dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement
    );
    console.log("serviciosPasajeroList");
    console.log(servicioPasajeroList);
    console.log(
      "DEBERIA ACTUALIZARSE LA LISTA DE SERVICIOS INDIVIDUALES--------------------------------"
    );
  }, [dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement]);

  const [currencyList, setCurrencyList] = useState<coinInterface[]>([]);
  useEffect(() => {
    setCurrencyList(catalogsData?.currency_list);
    console.log("VALOR ACTUAL DE LA MONEDA: ");
    console.log(currentCurrency);
  }, [catalogsData]);
  const totalDiaTarifa = servicioPasajeroList
    .map((servicioDia, index) => {
      return {
        totalDia: servicioDia?.serviciosIndividuales.reduce(
          (total, item) => total + item.tarifa * item.number_of_services,
          0
        ),
      };
    })
    .reduce((total, item) => total + item.totalDia, 0);

  return (
    <ServiciosDaysStyles>
      <div className="title-servicios">
        <h3>
          Servicios generales{" "}
          <span
            className="title-style"
            style={{
              color: "#29A5DC",
            }}
          >
            (costo de servicios transporte)
          </span>{" "}
        </h3>
        <br />
      </div>
      {numberDays > 0 ||
      dataCotizacionContext.personalInfo.dateStartOpen !== "" ||
      dataCotizacionContext.personalInfo.dateEndOpen ? (
        servicioPasajeroList?.map((servicio, index) => {
          return (
            <ElementServiceDay
              servicio={servicio}
              key={servicio.identifierDay}
              numberDay={index + 1}
              servicioPasajeroList={servicioPasajeroList}
              setServicioPasajeroList={setServicioPasajeroList}
              listSingle={listSingle}
              setListSingle={setListSingle}
              listTriple={listTriple}
              setListTriple={setListTriple}
            />
          );
        })[0]
      ) : (
        <h3 className="days-box">Selecciona un rango de días</h3>
      )}

      {totalDiaTarifa != 0 && serviciosType != 3 && (
        <div className="total-amount-services">
          <h3>Total de servicios individuales: {totalDiaTarifa.toFixed(2)}</h3>
        </div>
      )}
    </ServiciosDaysStyles>
  );
}

const ServiciosDaysStyles = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;

  .total-amount-services {
    width: 70%;
    margin: 0 auto;
  }
  .total-amount-services h3 {
    font-size: 0.9vw;
  }
  .days-box {
    width: 70%;
    margin: 0 auto;
  }
  @media screen and (max-width: 900px) {
    .days-box {
      width: 95%;
    }
    .total-amount-services h3 {
      font-size: 1.8vw;
    }
  }
  .title-servicios {
    color: rgb(8, 166, 165);
    width: 70%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 900px) {
    .title-servicios {
      width: 95%;
    }
  }
`;

const SelectListdefault = styled.select`
  width: 250px !important;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;

  border: 2px solid rgb(8, 166, 165);
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  max-height: 40.8px !important;
  .fake-icon {
    max-width: 10px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;
