import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import CotizacionInfo from "./CotizacionInfo";
import GeneralesCotizacion from "./GeneralesCotizacion";
import CustomInputt from "../../components/entrys/CustomInputt";
import { isEqual } from "lodash";


import {
  cotizacionTaxes,
  feeChargesI,
} from "../../../context/InterfacesContext";
import ElementComment from "./ElementComment";
interface courtesyId {
  courtesy_type_identifier: number;
  description: string;
}

export default function FinancialData() {
  const {
    setShowSearchServices,
    setServiciosType,
    dataCotizacionContext,
    setDataCotizacionContext,
  } = useContext(AppContext);

  const [finantialData, setFinantialData] = useState<feeChargesI>(
    dataCotizacionContext.feeCharges
  );

  useEffect(() => {
    console.log("Changing context");
    setDataCotizacionContext({
      ...dataCotizacionContext,
      feeCharges: finantialData,
    });
  }, [finantialData]);

  useEffect(()=>{
    console.log("Revisando repetición")
  if (!isEqual(dataCotizacionContext.feeCharges, finantialData)) {
    setFinantialData(dataCotizacionContext.feeCharges);
  }
   
  },[dataCotizacionContext.feeCharges])

  const [selectedOption, setSelectedOption] = useState<string>(
    dataCotizacionContext.feeCharges.courtesy_type_identifier == 3
      ? "Doble"
      : "Simple"
  );

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    setDataCotizacionContext({
      ...dataCotizacionContext,
      feeCharges: {
        ...dataCotizacionContext.feeCharges,
        courtesy_type_identifier:
          event.target.value == "Simple" ? 2 : "Doble" ? 3 : 1,
      },
    });
  };

  // const [opcionesCortesia, setOpcionesCortesia] = useState<courtesyId>();
  return (
    <FinancialDataStyles>
      <div className="tipo-cortesia">
        <h3 className="title-cortesia">
          Elige la base en la que deseas calcular tu(s) cortesía(s)
        </h3>
        <div className="label-container">
          <label className="radio-label">
            Sencilla
            <input
              type="radio"
              value="Simple"
              checked={selectedOption === "Simple"}
              onChange={handleOptionChange}
            />
          </label>
          <br />
          <label className="radio-label">
            Doble
            <input
              type="radio"
              value="Doble"
              checked={selectedOption === "Doble"}
              onChange={handleOptionChange}
            />
          </label>
        </div>
      </div>
      <div className="box-financial">
        <div>
          <h3>Tipo de Moneda</h3>
        </div>
        <div>
          <h3>Tipo de cambio</h3>
        </div>
        <div>
          <h3>Comisión %</h3>
        </div>
        <div>
          <h3>Contingencia/fee</h3>
        </div>
        <div>
          <h3>Cortesía</h3>
        </div>
      </div>
      <div className="box-content-financial">
        <div>
          <h3 className="info-h3">Dolar</h3>
        </div>
        <div>
          <input
            type="number"
            value={finantialData.changeType}
            onKeyPress={(e) => {
              const keyCode = e.keyCode || e.which;
              const keyValue = String.fromCharCode(keyCode);
              const regex = /[0-9.]|\./; // Expresión regular para números y punto decimal

              if (!regex.test(keyValue)) {
                console.log("BLOQUEADO");
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              const value = e.target.value;

              if (value === "") {
                setFinantialData({
                  ...finantialData,
                  changeType: 1,
                });
              } else {
                const parsedValue = parseFloat(value);
                if (!isNaN(parsedValue)) {
                  setFinantialData({
                    ...finantialData,
                    changeType: parsedValue,
                  });
                }
              }
            }}
          />
        </div>
        <div>
          <input
            type="number"
            onKeyPress={(e) => {
              const keyCode = e.keyCode || e.which;
              const keyValue = String.fromCharCode(keyCode);
              const regex = /[0-9]/;

              if (!regex.test(keyValue)) {
                e.preventDefault();
              }
            }}
            value={
              finantialData.comision !== null
                ? finantialData.comision.toString()
                : ""
            }
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFinantialData({
                  ...finantialData,
                  comision: 0,
                });
              } else {
                const parsedValue = parseInt(value);
                if (!isNaN(parsedValue)) {
                  setFinantialData({
                    ...finantialData,
                    comision: parseInt(e.target.value),
                  });
                }
              }
              ///////////////////
            }}
          />
        </div>
        <div>
          <input
            type="number"
            onKeyPress={(e) => {
              const keyCode = e.keyCode || e.which;
              const keyValue = String.fromCharCode(keyCode);
              const regex = /[0-9]/;

              if (!regex.test(keyValue)) {
                e.preventDefault();
              }
            }}
            value={
              finantialData.comisonAuto !== null
                ? finantialData.comisonAuto.toString()
                : ""
            }
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFinantialData({
                  ...finantialData,
                  comisonAuto: 0,
                });
              } else {
                const parsedValue = parseInt(value);
                if (!isNaN(parsedValue)) {
                  setFinantialData({
                    ...finantialData,
                    comisonAuto: parseInt(e.target.value),
                  });
                }
              }
              ///////////////////
            }}
          />
        </div>
        <div>
          <input
            type="text"
            value={
              finantialData.cortesia !== null
                ? finantialData.cortesia.toString()
                : ""
            }
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFinantialData({
                  ...finantialData,
                  cortesia: 0,
                });
              } else {
                const parsedValue = parseInt(value);
                if (!isNaN(parsedValue)) {
                  setFinantialData({
                    ...finantialData,
                    cortesia: parseInt(e.target.value),
                  });
                }
              }
              ///////////////////
            }}
          />
        </div>
      </div>

      <div className="box-field">
        <div className="box-field-child">
          <h3>Comentarios</h3>
          {finantialData.comments.map((comentario, index) => {
            return (
              <>
                <ElementComment
                  comentario={comentario}
                  key={comentario.commentIdentifier}
                  finantialData={finantialData}
                  setFinantialData={setFinantialData}
                />
              </>
            );
          })}
        </div>
      </div>
      <div className="comments-box">
       
      </div>
    </FinancialDataStyles>
  );
}

const FinancialDataStyles = styled.div`
  margin-top: 2rem;
  .label-container {
    display: flex;
    align-items: center;
    color: rgb(8, 166, 165);
    font-size: 1vw;
  }
  @media screen and (max-width: 900px) {
    .label-container {
      display: flex;
      align-items: center;
      color: rgb(8, 166, 165);
      font-size: 1.8vw;
    }
  }
  .radio-label {
    display: flex;
    margin-right: 1rem;
    align-items: center;
  }
  .radio-label input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid rgb(8, 166, 165); /* Cambia el color del borde según tus preferencias */
    border-radius: 0; /* Elimina la curvatura */
    outline: none;
    cursor: pointer;
    margin-left: 0.5rem;
  }

  .radio-label input[type="radio"]:checked {
    background-color: rgb(
      8,
      166,
      165
    ); /* Cambia el color de fondo cuando está seleccionado */
    border-color: black; /* Cambia el color del borde cuando está seleccionado */
  }
  .title-cortesia {
    color: rgb(8, 166, 165);
    margin-bottom: 1rem;
    font-size: 1.2vw;
  }
  .tipo-cortesia {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 900px) {
    .tipo-cortesia {
      width: 95%;
    }
    .title-cortesia {
      font-size: 1.8vw;
    }
  }
  .box-field h3 {
    color: rgb(8, 166, 165);
    margin-top: 2rem;
  }
  .box-fields {
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
  }
  .box-field {
    width: 70%;
    margin: 0 auto;
  }
  .box-field-child{
    width: 80%;
  }

  @media screen and (max-width: 900px) {
    .box-fields {
      width: 80%;
      margin: 0 auto;
      margin-top: 2rem;
      flex-direction: column;
    }
    .box-field {
      width: 95%;
      margin: 0 auto;
    }
  }

  .text-container {
    resize: none;
    width: 100%;
    height: 100px;
    padding: 0.9rem;
    border: rgb(8, 166, 165) solid 2px;
    outline: none;
  }
  .info-h3 {
    font-size: 0.85vw;
    text-align: center;
  }

  .comments-box {
    width: 70%;
    margin: 0 auto;
    margin-top: 2rem;
  }
  @media screen and (max-width: 900px) {
    .comments-box {
      width: 95%;
    }
    .info-h3 {
      font-size: 1.5vw;
    }
  }

  .box-content-financial div {
    padding: 0.2rem;
  }
  .box-financial {
    width: 70%;
    border: rgb(8, 166, 165) solid 2px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 0.5rem;
    margin: 0 auto;
    padding: 0.8rem;
    color: rgb(8, 166, 165);
    font-size: 0.85vw;
    align-items: center;
    justify-content: center;
  }
  .box-financial div h3 {
    text-align: center;
    font-size: 0.85vw;
  }
  .box-content-financial {
    border: black solid 1px;
    width: 70%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 0.5rem;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
  }
  .box-content-financial div input {
    width: 100%;
    max-width: 100%;
    font-size: 0.85vw;
  }
  @media screen and (max-width: 900px) {
    .box-financial {
      width: 95%;
      font-size: 1.6vw;
    }
    .box-content-financial {
      width: 95%;
    }
    .box-financial div h3 {
      font-size: 1.5vw;
    }
    .box-content-financial div input {
      font-size: 1.6vw;
    }
  }
`;
