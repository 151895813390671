import React, { useEffect, useContext } from "react";
import "./App.css";
import "./styles/botton.css";

import { useState } from "react";
import Navbar from "./alico/components/layout/Navbar";
import { BrowserRouter, Routes } from "react-router-dom";
import { BrowserRouter as Router, Route, Navigate } from "react-router-dom";
import AsideBar from "./alico/components/layout/AsideBar";
import styled from "styled-components";
import { ComponentType } from "react";
import Catalogo from "./alico/components/pages/Catalogo";
import Welcome from "./alico/components/pages/Welcome";
import Contabilidad from "./alico/components/pages/Contabilidad";
import ErrorPage from "./alico/components/pages/ErrorPage";
import Dashboard from "./alico/components/pages/Dashboard";
import Login from "./alico/components/pages/Login";
import NewElementPage from "./alico/pages/NewElementPage";
import Loader from "./alico/components/common/Loader";
import { AppContext } from "./context/appContext";
import AlertMessage from "./alico/components/common/AlertMessage";
import ProviderList from "./alico/components/pages/ProviderList";
import Stepeer from "./alico/pages/RegisterNewProvider-Client/Stepeer";
import ClientDetails from "./alico/components/pages/ClientDetails";
import AltaCotizacion from "./alico/pages/Cotizacion/AltaCotizacion";
import CotizacionInfo from "./alico/pages/Cotizacion/CotizacionInfo";
import SearchServices from "./alico/pages/Cotizacion/SearchServices";
import { CatalogsProvider } from "./context/CatalogContext";
import SearchCotizacion from "./alico/pages/Cotizacion/SearchCotizacion";
import PageGenerales from "./alico/pages/Cotizacion/PageGenerales";
import { CatalogsProviderDos } from "./context/CatalogContextDos";
import DeleteConfirmation from "./alico/components/common/DeleteConfirmation";
import ManageOperacion from "./alico/pages/Operaciones/ManageOperacion";
import { CatalogsProviderOperacion } from "./context/OperacionCatalog";
import SearchServicesDOS from "./alico/pages/Cotizacion/SearchServicesDOS";
import SearchOperacion from "./alico/pages/Operaciones/SearchOperacion";
import ManageQuejas from "./alico/pages/Operaciones/ManageQuejas";
import ManageContabilidad from "./alico/pages/Contabilidad/ManageContabilidad";
import ContabilidadSearchDos from "./alico/pages/Contabilidad/ContabilidadSearchDos";
import { CatalogsProviderContabilidad } from "./context/ContabilidadCatalogs";
import SearchPagos from "./alico/pages/Contabilidad/SearchPagos";
import Reembolsos from "./alico/pages/Contabilidad/Reembolsos/Reembolsos";

interface propData {
  titulo?: string;
}
interface styledAppProps {
  showSideBar: boolean;
}

function App(props: propData) {
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    window.screen.width;
  const [showSideBar, setShowSideBar] = useState<boolean>(true);
  const { showLoader, setShowLoader } = useContext(AppContext);
  const { showAlert, showInfoContact, showSeachServices, showSearchProviders } =
    useContext(AppContext);

  useEffect(() => {}, []);
  return (
    <BrowserRouter>
      <CatalogsProvider>
        <CatalogsProviderDos>
          <CatalogsProviderContabilidad>
            <CatalogsProviderOperacion>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />}>
                  <Route path="Registro/*" element={<Stepeer />} />
                  <Route path="Catalogo" element={<Catalogo />} />
                  <Route path="Providers-List" element={<ProviderList />} />
                  <Route path="Providers-Info" element={<ClientDetails />} />
                  {/* Para hacer funcionar las rutas relativas, debes colocar /* y luego ir al componente (AltaCotizacion) y ahí direccionar a la subruta por medio de un Router
            TAMBIEN TOMA EN CUENTA EL NAVIGATE
            */}
                  <Route
                    path="Alta-Cotizacion/*"
                    element={<AltaCotizacion />}
                  />
                  <Route
                    path="Registro-Cotizacion"
                    element={<AltaCotizacion />}
                  />
                  <Route path="Operaciones/*" element={<ManageOperacion />} />

                  <Route
                    path="Operaciones"
                    element={<Navigate to="Manage-Operacion" />}
                  />
                  {/* Se utiliza Navigate to, para mandar desde /Operaciones a /Operaciones/Manage-Operacion, una vez en ManageOperation, al Navegar a 
            a Manage-Operation, se sabe que en ManageOperacion se tiene un Router que indica hacia que componente direccionar en Manage-Operacion
            */}
                  <Route
                    path="Contabilidad/*"
                    element={<ManageContabilidad />}
                  />
                  <Route
                    path="Contabilidad"
                    element={<Navigate to="Manage-Contabilidad" />}
                  />
                  <Route
                    path="Contabilidad-Busqueda"
                    element={<SearchPagos />}
                  />
                  <Route
                    path="Contabilidad-Reembolsos"
                    element={<Reembolsos />}
                  />
                  {/* <Route path="Contabilidad-Prestadores" element = {<ContabilidadSearchDos/>}/> */}
                  <Route
                    path="List-Cotizacion"
                    element={<SearchCotizacion />}
                  />
                  <Route
                    path="Search-Operacion"
                    element={<SearchOperacion />}
                  />
                  <Route path="Quejas" element={<ManageQuejas />} />
                  <Route path="*" element={<ErrorPage />} />
                  <Route
                    path="Registro"
                    element={<Navigate to="Generales" />}
                  />
                  <Route
                    path="Alta-Cotizacion"
                    element={<Navigate to="Registro-Cotizacion" />}
                  />
                </Route>

                <Route path="*" element={<ErrorPage />} />
              </Routes>
              {showLoader && <Loader />}
              {showAlert && <AlertMessage />}
              {showInfoContact && <ClientDetails />}
              {showSeachServices && <SearchServices />}
              {showSearchProviders && <SearchServicesDOS />}
            </CatalogsProviderOperacion>
          </CatalogsProviderContabilidad>
        </CatalogsProviderDos>
      </CatalogsProvider>
    </BrowserRouter>
  );
}

export default App;

const AppStyles = styled.div<styledAppProps>`
  position: relative;
  .box-content {
    display: flex;
  }

  .second-section {
    width: 80%;
    background-color: #b1b1b1;
    display: ${(props) => (false ? "none" : "block")};
  }
  .content-app {
    position: absolute;
    width: ${(props) => (true ? "80%" : "100%")};
    left: ${(props) => (true ? "20%" : "0%")};
    top: 5rem;
  }

  @media screen and (min-width: 900px) {
    .second-section {
      display: block;
    }
  }
`;
