export function calcularNumeroDeNoches(diaUno: string, diaDos: string): number {
    // Convertir las cadenas de texto a objetos de tipo Date
    const fechaUno = new Date(diaUno);
    const fechaDos = new Date(diaDos);
  
    // Validar que las fechas sean válidas
    if (isNaN(fechaUno.getTime()) || isNaN(fechaDos.getTime())) {
      throw new Error("Una o ambas fechas no son válidas");
    }
  
    // Calcular la diferencia en milisegundos entre las dos fechas
    const diferenciaEnTiempo = fechaDos.getTime() - fechaUno.getTime();
  
    // Si la diferencia es negativa, significa que las fechas están invertidas
    if (diferenciaEnTiempo < 0) {
      throw new Error("La fecha de salida no puede ser anterior a la fecha de entrada");
    }
  
    // Calcular la diferencia en días (cada día tiene 24 horas, 60 minutos, 60 segundos, 1000 ms)
    const diferenciaEnDias = Math.ceil(diferenciaEnTiempo / (1000 * 60 * 60 * 24));
  
    return diferenciaEnDias;
  }
  